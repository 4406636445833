export const environment = {
  production: true,
  authenticated: false,
  hmr: false,
  apiBaseUrl: '/api',
  wallBaseUrl: 'https://stage-wall.dowow.tv',
  wallBaseDomain: '',
  zoomusProxyBaseUrl: 'https://stage-wall.dowow.tv/zoom/j/',
  YOUTUBE_API_URL: 'https://www.googleapis.com/youtube/v3/videos',
  YOUTUBE_API_KEY: 'AIzaSyCmfrQPxO6bol8ziUcytnlWUd35QaqXP1Q',
  YOUTUBE_API_SEARCH_URL: 'https://www.googleapis.com/youtube/v3/search',
  GOOGLE_DRIVE_CLIENT_ID: '709488987151-i6ajc3s6o9artvsndodhhecdng29psn3.apps.googleusercontent.com',
  GOOGLE_DRIVE_API_KEY: 'AIzaSyBYwPgyO4KtlnKQoREZNUHV9-FrZuYKk2k',
  DOWOW_OWL: 'https://content.dowow.tv/dowow-owl.png',
  DOWOW_OWL_FALLBACK: 'https://content.dowow.tv/dowowowlfallback.png',
  ZOOM_APP_CLIEND_ID: 'b9F0XRfCT76a6PUGudGJmg', // dowow stage
  ZOOM_REDIRECT_URI: 'https://stage.dowow.tv/settings/integrations', // dowow stage
  ZOOM_OAUTH_AUTHORIZE_LINK: 'https://zoom.us/oauth/authorize',
  ZOOM_OATH_REFERRER_URIS: ['https://marketplace.zoom.us/', 'https://zoom.us/oauth/authorize', 'https://zoom.us/'],
  ZOOM_OAUTH_TOKEN_LINK: 'https://zoom.us/oauth/token',
  RECAPTCHA_V3_SITE_KEY: '6LevhK0pAAAAAFAzUWnMgLtPjNtm8QMKvax6wQL4',
  RECAPTCHA_ENDPOINT_API_KEY: 'RG93b3dHb29nbGVSM2NhcHRjaGFBcGlLZXk=',
  ONEDRIVE_APPLICATION_ID: '60ea8702-3ded-4c96-b51c-c28e67649f01',
  ONEDRIVE_DIRECTORY_ID: '27b50ef0-1629-4a5f-97f5-dd9a8b04d4b6',
  ONEDRIVE_REDIRECT_URI: 'https://stage.dowow.tv/settings/integrations',
  ONEDRIVE_REFERRER_URIS: ['https://account.live.com/', 'https://login.microsoftonline.com/'],
  ONEDRIVE_OAUTH_AUTHORIZE_LINK: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  ONEDRIVE_LOGOUT_LINK: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  features: {
    playFullLength: true,
    syncedPlayback: true,
    liveStream: true,
    customDuration: true,
    zoomIntegration: true,
    stripePayments: true,
    recaptcha: true,
  },
  isCachable: {
    dclNft: true,
  },
  STRIPE_PAYMENTS: {
    successUrl: 'https://stage.dowow.tv/settings/application',
    cancelUrl: 'https://stage.dowow.tv/settings/application',
    MAIN_PRODUCT_META_INDEX_NAME: 'type',
    MAIN_PRODUCT_META_IDENTIFIER: 'main_subscription_item',
    CUSTOM_PRODUCT_ID: 'prod_PmJdMjtzb5b9Jt',
    DOWOW_PRODUCT_ID: 'prod_Oy2K7Tdv1JO8vL',
  },
  SUPPORT_LINK: 'https://dowow.tv/contact-us/',
  isStaged: true,
  ERRORS: {
    LOGIN_STATUS: {
      UNVERIFIED_USER_ACCOUNT: 'Unverified user account',
    },
  },
  DCL_MARKETPLACE_LINK: 'https://decentraland.org/marketplace',
  OPENSEA_ETHEREUM_MARKETPLACE_LINK: 'https://opensea.io/assets/ethereum',
}

import { inject, Injectable } from '@angular/core'
import { Route, Router, UrlSegment, CanMatchFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { FeatureService } from '../services/feature'
import { AppStateService } from '../services/app-state'

@Injectable({
  providedIn: 'root',
})
class FeatureGuardService {
  email: string = ''
  constructor(private featureFlagsService: FeatureService, private router: Router, private appStateService: AppStateService) {
    this.appStateService.getUserState().subscribe(user => {
      if (user) {
        this.email = user.profile.email
      }
    })
  }
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const {
      data: { feature, dowowUsersOnly },
    } = route
    if (feature) {
      const isDowowUser = this.email.endsWith('@dowow.tv')
      const isEnabled = dowowUsersOnly ? isDowowUser && this.featureFlagsService.isFeatureEnabled(feature) : this.featureFlagsService.isFeatureEnabled(feature)
      if (isEnabled) {
        return true
      }
    }
    this.router.navigate(['/'])
    return false
  }
}

export const FeatureGuard: CanMatchFn = (next: ActivatedRouteSnapshot) => {
  return inject(FeatureGuardService).canLoad(next)
}
